import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledButton = styled.button`
    color: ${props => props.secondary ? props.theme.color.gray70 : props.theme.color.white};
    background-color: ${props => props.secondary ? 'transparent' : props.theme.color.gray70};
    font-family: ${props => props.theme.fontFamily};
    font-size: ${props => props.theme.fontSize.standard};
    font-weight: ${props => props.theme.fontWeight.standardRegular};
    padding: 9px 16px;
    border: 0;
    height: 35px;
    min-height: 35px;
    max-height: 35px;
    min-width: 80px;
    width:${props => props.strechted ? '100%' : 'auto'};
    border-radius: 2px;
    cursor: pointer;
    display: inline-block;
    box-sizing: border-box;
    text-align: center;
    opacity: ${props => props.disabled ? props.theme.disabledOpacity : '1'};
    cursor: ${props => props.disabled ? 'default' : 'pointer'};
`

const ActionButtonComponent = ({ buttonText, className = '', strechted = false, disabled = false, onButtonClick, secondary = false }) => {
  return <StyledButton secondary={secondary} className={className} strechted={strechted} onClick={onButtonClick} disabled={disabled}>{buttonText}</StyledButton>
}

ActionButtonComponent.propTypes = {
  buttonText: PropTypes.string.isRequired,
  className: PropTypes.string,
  onButtonClick: PropTypes.func.isRequired,
  strechted: PropTypes.bool,
  disabled: PropTypes.bool,
  secondary: PropTypes.bool
}

export default ActionButtonComponent
