import React from 'react'
import PropTypes from 'prop-types'

import FullscreenPreloader from './fullscreen_preloader'
import LocalPreloader from './local_preloader'

const PreloaderComponent = ({...props}) => {
  const Wrapper = props.fullscreen ? FullscreenPreloader : LocalPreloader
  return <Wrapper {...props} />
}

PreloaderComponent.propTypes = {
  preloaderText: PropTypes.string,
  fullscreen: PropTypes.bool,
  showProgress: PropTypes.bool,
  progress: PropTypes.number,
  alwaysOnTop: PropTypes.bool
}

export default PreloaderComponent
