import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const StyledProgressWrapper = styled.div`
    margin: 40px auto 0;
    width: 300px;
    max-width: 90%;
    height: 20px;
    background: #ccc;
`

const StyledProgress = styled.div`
   width: ${props => props.progress}%;
   background: green;
   margin: 0;
   padding: 0; 
   height: inherit;
   transition: width 0.1s;
`

const ProgressBarComponent = ({progress}) => {
  return <StyledProgressWrapper>
    <StyledProgress progress={progress} />
  </StyledProgressWrapper>
}

ProgressBarComponent.propTypes = {
  progress: PropTypes.number.isRequired
}

export default ProgressBarComponent
