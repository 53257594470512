import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import preloader from './ajax-loader-2.gif'
import ProgressBar from '../progress_bar/component'

const StyledFullscreenPreloader = styled.div`
    box-sizing: border-box;
    position: fixed; 
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    background-color: white;
    background-color: rgba(255,255,255,${props => props.soft ? '0.5' : '0.8'});
    text-align: center;
    z-index: ${props => props.alwaysOnTop ? '9999' : '2000'};
`

const StyledContent = styled.div`
    margin: 40px auto 0;
`

const StyledPreloaderImage = styled.img`
    max-width: 160px;
`

const StyledMessage = styled.p`
    font-family: ${props => props.theme.fontFamily};
    font-size: ${props => props.theme.fontSize.headLine2};
    font-weight: ${props => props.theme.fontWeight.standardBold};
    color: ${props => props.theme.color.anthracite50};
    margin-top: -20px;
`

const FullscreenPreloaderComponent = ({ preloaderText = '', showProgress = false, progress = 0, alwaysOnTop = false, isVisible = true, soft = false }) => {
  return isVisible && <StyledFullscreenPreloader alwaysOnTop={alwaysOnTop} soft={soft}>
    <StyledContent>
      <StyledPreloaderImage src={preloader} alt='' />
      {preloaderText !== '' && <StyledMessage>{preloaderText}</StyledMessage>}
      {
        showProgress && <ProgressBar progress={Math.round(progress * 100)} />
      }
    </StyledContent>
  </StyledFullscreenPreloader>
}

FullscreenPreloaderComponent.propTypes = {
  preloaderText: PropTypes.string,
  showProgress: PropTypes.bool,
  progress: PropTypes.number,
  alwaysOnTop: PropTypes.bool,
  isVisible: PropTypes.bool,
  soft: PropTypes.bool
}

export default FullscreenPreloaderComponent
