import styled from 'styled-components'

export const StyledInput = styled.input`
    border: 0;
    border-bottom: 2px solid ${props => props.inverted ? props.theme.color.white : props.theme.color.gray15};
    font-family: ${props => props.theme.fontFamily};
    padding: 4px;
    &:focus {
        outline: none;
        background: ${props => props.inverted ? 'transparent' : props.theme.color.white};
    }
    &:disabled {
        background: #ffffff;
    }
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    background: ${props => props.inverted ? 'transparent' : props.theme.color.white};
    color: ${props => props.inverted ? props.theme.color.white : props.theme.color.black};
    &::placeholder{
        color: ${props => props.inverted ? props.theme.color.white : props.theme.color.gray70};
    }
`

export default StyledInput
