import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import preloader from './ajax-loader.gif'

const StyledPreloader = styled.div`
    width: 100%;
    padding-top: 20px;
    text-align: center;
`

const StyledMessage = styled.p`
    padding-top: 10px;
    font-family: ${props => props.theme.fontFamily};
    font-size: ${props => props.theme.fontSize.headline2};
    font-weight: ${props => props.theme.fontWeight.standardBold};
    color: ${props => props.theme.color.anthracite50};
`

const LocalPreloaderComponent = ({ preloaderText = '' }) => {
  return <StyledPreloader>
    <img src={preloader} alt='' />
    {preloaderText !== '' && <StyledMessage>{preloaderText}</StyledMessage>}
  </StyledPreloader>
}

LocalPreloaderComponent.propTypes = {
  preloaderText: PropTypes.string
}

export default LocalPreloaderComponent
