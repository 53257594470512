import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const StyledEmptyListPlaceholder = styled.div``

const StyledHeadline = styled.h3`
    font-family: ${props => props.theme.fontFamily};
    font-size: ${props => props.theme.fontSize.standard};
    font-weight: ${props => props.theme.fontWeight.standardBold};
`

const StyledContent = styled.p`
    font-family: ${props => props.theme.fontFamily};
    font-size: ${props => props.theme.fontSize.standard};
    font-weight: ${props => props.theme.fontWeight.standardRegular};
`

const EmptyListPlaceholderComponent = ({headline = '', textBody}) => {
  return <StyledEmptyListPlaceholder>
    {
      headline !== '' && <StyledHeadline>{headline}</StyledHeadline>
    }
    <StyledContent>{textBody}</StyledContent>
  </StyledEmptyListPlaceholder>
}

EmptyListPlaceholderComponent.propTypes = {
  headline: PropTypes.string,
  textBody: PropTypes.string.isRequired
}

export default EmptyListPlaceholderComponent
