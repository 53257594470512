import React from 'react'
import PropTypes from 'prop-types'
import styled, {withTheme} from 'styled-components'

import FontIcon from '../font_icon/component'

const StyledContainer = styled.span`
    cursor: ${props => props.disabled ? 'default' : 'pointer'};
    align-items: center;
    align-content: center;
    justify-content: center;
    flex: 0 0 ${props => props.widthProp};
    width: ${props => props.widthProp};
    display: flex;
    height: ${props => props.heightProp};
    box-sizing: border-box;
`

const IconButtonComponent = ({ iconKey, onButtonClick, title = '', disabled = false, width = '48px', height = '48px', theme, iconSize = '24px', color = 'black' }) => {
  return (
    <StyledContainer onClick={(e) => {
      e.preventDefault()
      e.stopPropagation()
      if (!disabled) {
        onButtonClick()
      }
    }} disabled={disabled} widthProp={width} heightProp={height}>
      <FontIcon icon={iconKey} isClickable={!disabled} title={title} color={disabled ? theme.color.anthracite50 : color !== '' ? color : theme.color.anthracite} fontSize={iconSize} />
    </StyledContainer>
  )
}

IconButtonComponent.propTypes = {
  iconKey: PropTypes.string.isRequired,
  onButtonClick: PropTypes.func.isRequired,
  title: PropTypes.string,
  disabled: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
  theme: PropTypes.object,
  iconSize: PropTypes.string,
  color: PropTypes.string
}

export default withTheme(IconButtonComponent)
