import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import FontIcon from '../font_icon/component'

const StyledContextMenuItemComponent = styled.li`
    padding: 8px;
    background:  ${props => props.theme.color.gray70};
    color: ${props => props.theme.color.white};
    cursor: pointer;
    display: inline-block;
    display: flex;
    flex-direction: row;
    &:hover{
        background:  ${props => props.theme.color.anthracite};
    }
`

const StyledBox = styled.span`
    align-self: center;
    padding-right: 10px;
`

const ContextMenuItemComponent = ({onListElementClick, iconKey, children, className = '', title = ''}) => {
  return <StyledContextMenuItemComponent onClick={onListElementClick} className={className} title={title}>
    <StyledBox>
      <FontIcon icon={iconKey} isClickable={true} color='white' />
    </StyledBox>
    <StyledBox>
      {children}
    </StyledBox>
  </StyledContextMenuItemComponent>
}

ContextMenuItemComponent.propTypes = {
  onListElementClick: PropTypes.func.isRequired,
  iconKey: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  title: PropTypes.string
}

export default ContextMenuItemComponent
