
import React from 'react'
import PropTypes from 'prop-types'

import StyledInput from '../../fragments/styled_input'

const FormInputTextComponent = ({ type = 'text', placeholder = '', value = '', onChange, onKeyPress, inverted = false }) => {
  return <StyledInput inverted={inverted} type={type} placeholder={placeholder} value={value} onChange={(e) => { onChange(e.target.value) }} onKeyPress={(e) => { onKeyPress(e) }} />
}

FormInputTextComponent.propTypes = {
  type: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onKeyPress: PropTypes.func,
  inverted: PropTypes.bool
}

export default FormInputTextComponent
