import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledDate = styled.span`
    font-family: ${props => props.theme.fontFamily};
`

const DateComponent = ({ date, todayString = '' }) => {
  const theDate = date instanceof Date ? date.toLocaleDateString() : new Date(date).toLocaleDateString()
  const today = new Date(Date.now()).toLocaleDateString()
  const todayStringFinal = todayString !== '' ? todayString : 'today'
  const displayedString = theDate === today ? todayStringFinal : theDate
  return (
    <StyledDate>{displayedString}</StyledDate>
  )
}

DateComponent.propTypes = {
  date: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date)
  ]).isRequired,
  todayString: PropTypes.string
}

export default DateComponent
