import React, {useState} from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import IconButton from '../icon_button/component'

export const StyledContainer = styled.div`
    margin: 0 0 16px 0;
    padding: 0;
    border: 1px solid  ${props => props.theme.color.gray15};
    box-shadow: rgba(0,0,0,0.0470588) 0px 0px 3px 1px;
`

export const StyledHeader = styled.div`
    box-sizing: border-box;
    margin: 0;
    padding: 16px;
    cursor: pointer;
    display: flex;
    background-color: ${props => props.theme.color.gray15};
`

export const StyledHeaderFont = styled.div`
    flex: 1 1 auto;
    font-family: ${props => props.theme.fontFamily};
    font-size: ${props => props.theme.fontSize.headLine2};
    font-weight: ${props => props.theme.fontWeight.headLine2};
`

export const StyledContent = styled.div`
    box-sizing: border-box;
    padding: 16px;
    border-top: 1px solid  ${props => props.theme.color.gray15};
    background-color: ${props => props.theme.color.lightGray};
`

const CollapsableWidgetComponent = ({children, headline, isCollapsedOnInit = false}) => {
  const [isCollapsed, setIsCollapsed] = useState(isCollapsedOnInit)
  return <StyledContainer>
    <StyledHeader onClick={() => { setIsCollapsed(!isCollapsed) }} className='collapsableWidgetHeader'>
      <StyledHeaderFont>{headline}</StyledHeaderFont>
      <IconButton onButtonClick={() => { setIsCollapsed(!isCollapsed) }} className='closeBtn' iconKey={isCollapsed ? 'chevron_left' : 'expand_more'} width='32px' height='22px' />
    </StyledHeader>
    {
      !isCollapsed && <StyledContent>{children}</StyledContent>
    }
  </StyledContainer>
}

CollapsableWidgetComponent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  headline: PropTypes.string.isRequired,
  isCollapsedOnInit: PropTypes.bool
}

export default CollapsableWidgetComponent
