import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Select from 'react-select'

import { StyledLabel } from '../../fragments/styled_form_components'

const StyledSelectWrapper = styled.div`
    &>div>div{
        border-radius: 0;
        border-color: ${props => props.theme.color.gray15};
    }
    margin-bottom: 13px;
    & *{
        font-family: ${props => props.theme.fontFamily};
        font-size: ${props => props.theme.fontSize.standard};
        font-weight: ${props => props.theme.fontWeight.standardRegular};
    }
`

const FormSelectComponent = ({options, defaultValue, onChange, label = ''}) => {
  return <StyledSelectWrapper>
    {
      label !== '' && <StyledLabel>{label}</StyledLabel>
    }
    <Select
      defaultValue={defaultValue}
      onChange={onChange}
      options={options}
    />
  </StyledSelectWrapper>
}

FormSelectComponent.propTypes = {
  options: PropTypes.array.isRequired,
  defaultValue: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string
}

export default FormSelectComponent
