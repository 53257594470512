import styled from 'styled-components'

export const StyledValidationMessage = styled.p`
    font-family: ${props => props.theme.fontFamily};
    font-size: ${props => props.theme.fontSize.small};
    font-weight: ${props => props.theme.fontWeight.smallRegular};
    color: ${props => props.theme.color.red};
    margin: 0;
`

export const StyledLabel = styled.label`
    font-family: ${props => props.theme.fontFamily};
    font-size: ${props => props.theme.fontSize.small};
    font-weight: ${props => props.theme.fontWeight.standardRegular};
    color:${props => props.theme.color.anthracite};
    display: block;
    margin-bottom: 4px;
`

export const StyledCheckboxLabel = styled.label`
    font-family: ${props => props.theme.fontFamily};
    font-size: ${props => props.theme.fontSize.regular};
    font-weight: ${props => props.theme.fontWeight.regular};
    color:${props => props.theme.color.anthracite};
    display: block;
    margin-bottom: 4px;
`