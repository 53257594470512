import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import uuidv4 from 'uuid/v4'
import { StyledValidationMessage, StyledLabel } from '../../fragments/styled_form_components'

const StyledInput = styled.input`
    font-family: ${props => props.theme.fontFamily};
    font-size: ${props => props.theme.fontSize.standard};
    font-weight: ${props => props.theme.fontWeight.standardRegular};
    color: ${props => props.theme.color.anthracite};
    border: 1px solid ${props => props.cssValidationError ? props.theme.color.red : props.theme.color.gray15};
    box-shadow: none;
    height: 35px;
    box-sizing: border-box;
    padding: 0 9px;
    width: ${props => props.cssWidth};
    margin-bottom: ${props => !props.cssValidationError ? '13px' : '0'};
`

const FormInputWithLabelComponent = ({value, onChange, label = '', placeholder = '', type = 'text', width = '100%', isRequired = false, validationHandlers = {}, validationErrors = []}) => {
  const htmlId = uuidv4()
  const finalLabel = isRequired ? label + '*' : label
  return <React.Fragment>
    {
      label !== '' && <StyledLabel htmlFor={htmlId}>{finalLabel}</StyledLabel>
    }
    <StyledInput id={htmlId} type={type} value={value} onChange={onChange} placeholder={placeholder} cssWidth={width} cssValidationError={validationErrors.length > 0} {...validationHandlers} />
    {
      validationErrors.length > 0 && <StyledValidationMessage>{
        validationErrors.map((e, i) => {
          return <span key={i}>{e}{i < validationErrors.length - 1 && ', '}</span>
        })
      }</StyledValidationMessage>
    }
  </React.Fragment>
}

FormInputWithLabelComponent.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  width: PropTypes.string,
  isRequired: PropTypes.bool,
  placeholder: PropTypes.string,
  validationHandlers: PropTypes.object,
  validationErrors: PropTypes.array
}

export default FormInputWithLabelComponent
