import React, {useState, useEffect, useRef} from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const StyledVideoWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
`

const StyledVideo = styled.video`
    width: ${props => props.isHidden ? '0' : '100%'};
    height: ${props => props.isHidden ? '0' : '100%'};
    opacity: ${props => props.isHidden ? '0' : '1'};
    object-fit: ${props => props.streched ? 'fill' : 'cover'};
`

const StyledChildren = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    z-index: 2;
    background-color: rgba(0, 0, 0, ${props => props.dim});
    transition: background ${props => props.dimmerSpeed}s ease-out;
    ${props => props.theme.splash.lightFont ? `
        background-color: rgba(0, 0, 0, ${props.dim});
    ` : `
        background-color: rgba(255, 255, 255, ${props.dim});
    `}
`

const BackgroundVideo = ({ videoSources, dim = '0', dimmerSpeed = '.25', preloadVideos = false, children }) => { // .25 matches the default phase transition time
  const [videoIndex, setVideoIndex] = useState(0)
  const player = useRef(null)

  useEffect(() => {
    player.current.load() // reload the player to accept the new src
    player.current.play()
  }, [videoIndex])

  const setNext = () => {
    let nextIndex = videoIndex === videoSources.length - 1 ? 0 : videoIndex + 1
    setVideoIndex(nextIndex)
  }

  return <React.Fragment>
    <StyledVideoWrapper>
      {
        videoSources.length === 1 // if there's just one video, use the loop attribute
          ? <StyledVideo id='background-video' ref={player} loop autoPlay muted playsInline>
            <source src={videoSources[0]} type='video/mp4' />
            <source src={videoSources[0]} type='video/ogg' />
            Your browser does not support the video tag.
          </StyledVideo>
          : preloadVideos
            ? videoSources.map((s, i) => { // if we load all videos in the beginning, there is no waiting time between them
              return <StyledVideo id='background-video' ref={player} autoPlay muted playsInline onEnded={setNext} key={i} isHidden={i !== videoIndex}>
                <source src={s} type='video/mp4' />
                <source src={s} type='video/ogg' />
                Your browser does not support the video tag.
              </StyledVideo>
            })
            : <StyledVideo id='background-video' ref={player} autoPlay muted playsInline onEnded={setNext}>
              <source src={videoSources[videoIndex]} type='video/mp4' />
              <source src={videoSources[videoIndex]} type='video/ogg' />
              Your browser does not support the video tag.
            </StyledVideo>
      }
    </StyledVideoWrapper>
    <StyledChildren dim={dim} dimmerSpeed={dimmerSpeed}>{children}</StyledChildren>
  </React.Fragment>
}

BackgroundVideo.propTypes = {
  videoSources: PropTypes.array.isRequired,
  dim: PropTypes.string,
  dimmerSpeed: PropTypes.string,
  preloadVideos: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
}

export default BackgroundVideo
