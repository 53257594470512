import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const StyledButton = styled.button`
    border: 1px solid ${props => props.outlined ? props.theme.splash.lightFont ? props.theme.color.white : props.theme.color.gray70 : 'transparent'};
    background: ${props => props.outlined ? 'rgba(0, 0, 0, 0.3)' : 'transparent'};
    font-family: ${props => props.theme.fontFamily};
    font-size: ${props => props.theme.fontSize.large};
    color: ${props => props.theme.splash.lightFont ? props.theme.color.white : props.theme.color.gray70};
    width: ${props => props.stretched ? '100%' : 'auto'};
    border-radius: 3px;
    height: fit-content;
    padding: 10px 20px;
    cursor: pointer;
    &:focus {outline:0;}
    text-shadow: ${props => props.theme.splash.lightFont ? '0px 0px 4px rgba(0, 0, 0, 0.3)' : 'none'};
`

const ActionButton = ({ text, stretched = false, outlined = true, onButtonClick }) => {
  return <StyledButton outlined={outlined} stretched={stretched} onClick={(e) => { e.preventDefault(); onButtonClick(e) }}>{text}</StyledButton>
}

ActionButton.propTypes = {
  text: PropTypes.string.isRequired,
  stretched: PropTypes.bool,
  outlined: PropTypes.bool,
  onButtonClick: PropTypes.func.isRequired
}

export default ActionButton
