import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import uuidv4 from 'uuid/v4'
import { StyledValidationMessage, StyledLabel } from '../../fragments/styled_form_components'

const StyledTextarea = styled.textarea`
    font-family: ${props => props.theme.fontFamily};
    font-size: ${props => props.theme.fontSize.standard};
    font-weight: ${props => props.theme.fontWeight.standardRegular};
    color: ${props => props.theme.color.anthracite};
    border: 1px solid ${props => props.cssValidationError ? props.theme.color.red : props.theme.color.gray15};
    box-shadow: none;
    height: 35px;
    box-sizing: border-box;
    padding: 9px;
    width: ${props => props.cssWidth};
    min-height: ${props => props.cssMinHeight};
    resize: none;
`

const FormTextareaComponent = ({value, onChange, label = '', placeholder = '', width = '100%', minHeight = '120px', isRequired = false, validationHandlers = {}, validationErrors = []}) => {
  const htmlId = uuidv4()
  const finalLabel = isRequired ? label + '*' : label
  return <React.Fragment>
    {
      label !== '' && <StyledLabel htmlFor={htmlId}>{finalLabel}</StyledLabel>
    }
    <StyledTextarea id={htmlId} value={value} onChange={onChange} placeholder={placeholder} cssWidth={width} cssMinHeight={minHeight} cssValidationError={validationErrors.length > 0} {...validationHandlers} />
    {
      validationErrors.length > 0 && <StyledValidationMessage>{
        validationErrors.map((e, i) => {
          return <span key={i}>{e}{i < validationErrors.length - 1 && ', '}</span>
        })
      }</StyledValidationMessage>
    }
  </React.Fragment>
}

FormTextareaComponent.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  width: PropTypes.string,
  minHeight: PropTypes.string,
  isRequired: PropTypes.bool,
  validationHandlers: PropTypes.object,
  validationErrors: PropTypes.array
}

export default FormTextareaComponent
