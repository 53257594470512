import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import onClickOutside from 'react-onclickoutside'

import FontIcon from '../font_icon/component'

const StyledContextMenu = styled.span`
    position: relative;
`

const StyledContextMenuList = styled.ul`
    position: absolute;
    top: 10px;
    right: 0;
    list-style-type: none;
`

class ContextMenuComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {menuOpen: props.menuOpen}
  }
    handleMenu = evt => {
      this.setState({menuOpen: !this.state.menuOpen})
    }
    handleClickOutside = evt => {
      this.setState({menuOpen: false})
    }
    render() {
      return <StyledContextMenu onClick={this.handleMenu}>
        <FontIcon icon='more_horiz' color='gray' isClickable={true} />
        {
          this.state.menuOpen && <StyledContextMenuList>
            {
              this.props.children
            }
          </StyledContextMenuList>
        }
      </StyledContextMenu>
    }
}

ContextMenuComponent.defaultProps = { // can be opened by default, for testing purposes
  menuOpen: false
}

ContextMenuComponent.propTypes = {
  menuOpen: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

export default onClickOutside(ContextMenuComponent)
