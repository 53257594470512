import React from 'react'
import PropTypes from 'prop-types'
import styled, {withTheme} from 'styled-components'
import uuidv4 from 'uuid/v4'
import { StyledValidationMessage, StyledCheckboxLabel } from '../../fragments/styled_form_components'
import Checkbox from 'react-simple-checkbox'

const StyledRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: baseline;
`
const StyledCheckboxContainer = styled.div`
    flex: 0 0 28px;
    &>*{
        position: relative;
        left: -3px;
    }
`
const StyledLabelContainer = styled.div`
    flex: 0 1 auto;
    height: 24px;
`

const FormCheckboxComponent = ({label, isRequired = false, validationHandlers = {}, validationErrors = [], theme}) => {
  const htmlId = uuidv4()
  const finalLabel = isRequired ? label + '*' : label
  return <React.Fragment>
    <StyledRow>
      <StyledCheckboxContainer>
        <Checkbox checked={validationHandlers.value} onChange={validationHandlers.onChange} color={theme.color.gray70} size={2} tickAnimationDuration={200} />
      </StyledCheckboxContainer>
      <StyledLabelContainer>
        <StyledCheckboxLabel className='checkboxLabel' onClick={() => { validationHandlers.onChange(!validationHandlers.value) }} htmlFor={htmlId}>{finalLabel}</StyledCheckboxLabel>
      </StyledLabelContainer>
    </StyledRow>
    {
      validationErrors.length > 0 ? <StyledRow>
        <StyledCheckboxContainer />
        <StyledLabelContainer>
          <StyledValidationMessage>{
            validationErrors.map((e, i) => {
              return <span key={i}>{e}{i < validationErrors.length - 1 && ', '}</span>
            })
          }</StyledValidationMessage>
        </StyledLabelContainer>
      </StyledRow> : <StyledRow><StyledLabelContainer>&nbsp;</StyledLabelContainer></StyledRow>
    }
  </React.Fragment>
}

FormCheckboxComponent.propTypes = {
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  validationHandlers: PropTypes.object,
  validationErrors: PropTypes.array,
  theme: PropTypes.object.isRequired
}

export default withTheme(FormCheckboxComponent)
