import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledFontIcon = styled.i`
    color: ${props => props.color};
    cursor: ${props => props.isClickable ? 'pointer' : 'default'};
    font-size: ${props => props.fontSize};
`
const FontIconComponent = ({ icon, color = 'black', hoverColor = 'gray', title = '', isClickable = false, fontSize = '24px' }) => {
  return (
    <StyledFontIcon className='material-icons' title={title} color={color} hoverColor={hoverColor} fontSize={fontSize} isClickable={isClickable}>{icon}</StyledFontIcon>
  )
}

FontIconComponent.propTypes = {
  icon: PropTypes.string.isRequired,
  color: PropTypes.string,
  fontSize: PropTypes.string,
  title: PropTypes.string,
  hoverColor: PropTypes.string,
  isClickable: PropTypes.bool
}

export default FontIconComponent
