import React from 'react'
import PropTypes from 'prop-types'

const TimeComponent = ({ date, timeHourSuffix = 'h' }) => {
  return (
    <span>{date instanceof Date ? date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) : new Date(date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}{timeHourSuffix}</span>
  )
}

TimeComponent.propTypes = {
  date: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date)
  ]).isRequired,
  timeHourSuffix: PropTypes.string
}

export default TimeComponent
