import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledTag = styled.div`
    box-sizing: border-box;
    background-color: ${props => props.backgroundColor};
    color: ${props => props.theme.color.white};
    font-size: ${props => props.theme.fontSize.small};
    font-weight: ${props => props.theme.fontWeight.smallBold};
    text-align: center;
    width: ${props => props.width};
    margin: 4px 0;
    min-height: 18px
    padding: 3px 8px 0;
    border-radius: 2px;
    word-break: break-word;
    display: ${props => props.inline ? 'inline-block' : 'block'};
    margin-right: ${props => props.inline ? '4px' : '0'};
`

const TagComponent = ({ backgroundColor = '', width = '100%', color = '', inline = false, children, theme }) => {
  const bg = backgroundColor !== '' ? backgroundColor : theme.color.lightPowderGray
  const c = color !== '' ? color : theme.color.white
  return (
    <StyledTag className='tag' backgroundColor={bg} color={c} width={width} inline={inline}>{children}</StyledTag>
  )
}

TagComponent.propTypes = {
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
  width: PropTypes.string,
  inline: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string
  ]).isRequired,
  theme: PropTypes.object.isRequired
}

export default TagComponent
